import { createSlice } from '@reduxjs/toolkit';

import { initialColors } from './initialColors';

const colorSlice = createSlice({
  name: 'colors',
  initialState: initialColors,
  reducers: {
    setColorData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setColorData } = colorSlice.actions;

export default colorSlice.reducer;

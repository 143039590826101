import React from 'react';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './redux/store';
import DynamicMain from './components/Main/DynamicMain';
import Index from './components/Common/Index/Index';

import './styles/reset.css';
import './App.css';
import './styles/default.css';

const App = () => {
  return (
    <Provider store={store}>
      <div className='App'>
        <Router>
          <Routes>
            <Route path='/' element={<Index />} />
            <Route path='/wedding/:id' element={<DynamicMain />} />
            <Route path='/invitation/:id' element={<DynamicMain />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </Router>
      </div>
    </Provider>
  );
};

export default App;

import React from 'react';

import loadingImg from '../../../assets/Loading.svg';
import styles from './Loading.module.css';

const Loading = ({ backgroundColor }) => {
  return (
    <div className={`${styles.Loading} Modal`} style={{ backgroundColor: `rgba(${backgroundColor}, 0.3)` }}>
      <img src={loadingImg} alt='Loading' height={200} width={200} style={{ width: '100px' }} />
    </div>
  );
};

export default Loading;
